<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <wolk-private-data-alert />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Exports

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <p>Selecteer een export, de van en tot datum en klik op downloaden</p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>
            Gebruikers logins

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-dialog
                  ref="userTokensFromDateDialog"
                  v-model="userTokens.fromDateModal"
                  :return-value.sync="userTokens.fromDate"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="humanizedUserTokensFromDate"
                      :hide-details="true"
                      label="Van"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="userTokens.fromDate"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="userTokens.fromDateModal = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.userTokensFromDateDialog.save(userTokens.fromDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="6">
                <v-dialog
                  ref="userTokensToDateDialog"
                  v-model="userTokens.toDateModal"
                  :return-value.sync="userTokens.toDate"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="humanizedUserTokensToDate"
                      :hide-details="true"
                      label="Tot"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="userTokens.toDate"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="userTokens.toDateModal = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.userTokensToDateDialog.save(userTokens.toDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="downloadUserTokenData"
                >
                  Downloaden
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>
            Organisatie data

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-dialog
                  ref="organizationDataFromDateDialog"
                  v-model="organizationData.fromDateModal"
                  :return-value.sync="organizationData.fromDate"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="humanizedOrganizationDataFromDate"
                      :hide-details="true"
                      label="Van"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="organizationData.fromDate"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="organizationData.fromDateModal = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.organizationDataFromDateDialog.save(organizationData.fromDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="6">
                <v-dialog
                  ref="organizationDataToDateDialog"
                  v-model="organizationData.toDateModal"
                  :return-value.sync="organizationData.toDate"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="humanizedOrganizationDataToDate"
                      :hide-details="true"
                      label="Tot"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="organizationData.toDate"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="organizationData.toDateModal = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.organizationDataToDateDialog.save(organizationData.toDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="organizationData.selectedIds"
                  :items="organizations"
                  item-text="title"
                  item-value="_id"
                  prepend-icon="mdi-domain"
                  label="Organisaties"
                  multiple
                >
                  <template #selection="{ item, index }">
                    <v-chip v-if="index < 5">
                      <span>{{ item.title }}</span>
                    </v-chip>

                    <span
                      v-if="index === 5"
                      class="grey--text caption"
                    >
                      (en {{ organizationData.selectedIds.length - 5 }} anderen)
                    </span>
                  </template>
                </v-select>

                <v-btn @click="toggleAllOrganizations">
                  {{ organizationData.selectedIds.length === 0 ? 'Selecteer' : 'Deselecteer' }}
                  alle organisaties
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-alert
                  v-if="!canRequestOrganizationData"
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  Kies een kleinere selectie
                </v-alert>

                <v-btn
                  :disabled="!canRequestOrganizationData"
                  color="primary"
                  @click="downloadOrganizationAnalytics"
                >
                  Downloaden
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-restricted-syntax */

import axios from 'axios';
import { saveAs } from 'file-saver';

import { DateTime } from '../helpers/dateTime';

import { createBreadcrumbs } from '../helpers/humanizer';

import WolkPrivateDataAlert from '../components/WolkPrivateDataAlert.vue';

const dateFormat = {
  ...DateTime.DATE_FULL,
  ...{ locale: 'nl-NL' },
};

export default {
  name: 'WolkExportsPage',
  components: {
    WolkPrivateDataAlert,
  },
  data() {
    return {
      organizations: [],
      userTokens: {
        fromDate: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
        fromDateModal: false,
        toDate: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
        toDateModal: false,
      },
      organizationData: {
        fromDate: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
        fromDateModal: false,
        toDate: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
        toDateModal: false,
        selectedIds: [],
      },
    };
  },
  computed: {
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Exports',
            disabled: true,
            exact: true,
            to: { name: 'exports' },
          },
        ],
        false,
      );
    },
    organizationDataRequestSize() {
      const fromDate = DateTime.fromISO(this.organizationData.fromDate);
      const toDate = DateTime.fromISO(this.organizationData.toDate);
      const diffInDays = toDate.diff(fromDate, 'days').values.days;

      if (diffInDays <= 0) {
        return 0;
      }

      return this.organizationData.selectedIds.length * diffInDays;
    },
    canRequestOrganizationData() {
      return this.organizationDataRequestSize <= 10000;
    },
    humanizedUserTokensFromDate() {
      return this.humanizeDate(this.userTokens.fromDate);
    },
    humanizedUserTokensToDate() {
      return this.humanizeDate(this.userTokens.toDate);
    },
    humanizedOrganizationDataFromDate() {
      return this.humanizeDate(this.organizationData.fromDate);
    },
    humanizedOrganizationDataToDate() {
      return this.humanizeDate(this.organizationData.toDate);
    },
  },
  beforeMount() {
    this.loadOrganizations();
  },
  methods: {
    humanizeDate(isoDateString) {
      return DateTime.fromISO(isoDateString).toLocaleString(dateFormat);
    },
    loadOrganizations() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations`;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          this.organizations = response
            .data
            .organizations
            .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
        });
    },
    toggleAllOrganizations() {
      if (this.organizationData.selectedIds.length === 0) {
        this.organizationData.selectedIds = this.organizations.map((o) => o._id);
      } else {
        this.organizationData.selectedIds = [];
      }
    },
    downloadUserTokenData() {
      const url = `${config.VUE_APP_API_BASE_URL}/analytics/export`;
      const params = {
        type: 'customerTokensCreated',
        fromDate: this.userTokens.fromDate,
        toDate: this.userTokens.toDate,
      };

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        params,
      })
        .then((response) => {
          const csvString = response.data.map((ct) => `${ct.date},${ct.time},${ct.userEmail},${ct.organizationTitle}`).join('\n');

          const file = new File(
            [csvString],
            `GebruikersLogins_${this.userTokens.fromDate}_tm_${this.userTokens.toDate}.csv`,
            { type: 'text/csv;charset=utf-8' },
          );

          saveAs(file);
        });
    },
    downloadOrganizationAnalytics() {
      if (this.organizationData.selectedIds.length < 1) {
        return;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/analytics/export`;

      const params = {
        type: 'organizationAnalytics',
        fromDate: this.organizationData.fromDate,
        toDate: this.organizationData.toDate,
        organizationIds: this.organizationData.selectedIds.join(','),
      };

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        params,
      })
        .then((response) => {
          let csvString = 'date,orgTitle,activeDays,activations,activationsLeft,activationsRight,onTimeVerySoft,onTimeSoft,onTimeHard,onTimeVeryHard,notOnTimeVerySoft,notOnTimeSoft,notOnTimeHard,notOnTimeVeryHard,falsePositive,nonAnalysed\n';

          for (const [dateString, orgEntries] of Object.entries(response.data)) {
            for (const [orgTitle, value] of Object.entries(orgEntries)) {
              csvString += `${dateString},${orgTitle},${value.activeDays},${value.activations},`;

              if (value.activations > 0 && value.activationAnalysesData) {
                csvString += `${value.activationAnalysesData.left},${value.activationAnalysesData.right},${value.activationAnalysesData.onTime.verySoft},${value.activationAnalysesData.onTime.soft},${value.activationAnalysesData.onTime.hard},${value.activationAnalysesData.onTime.veryHard},${value.activationAnalysesData.notOnTime.verySoft},${value.activationAnalysesData.notOnTime.soft},${value.activationAnalysesData.notOnTime.hard},${value.activationAnalysesData.notOnTime.veryHard},${value.activationAnalysesData.falsePositive},${value.activationAnalysesData.nonAnalysed}`;
              } else {
                csvString += '0,0,0,0,0,0,0,0,0,0,0,0';
              }

              csvString += '\n';
            }
          }

          const file = new File(
            [csvString],
            `organisatieData_${this.organizationData.fromDate}_tm_${this.organizationData.toDate}.csv`,
            { type: 'text/csv;charset=utf-8' },
          );

          saveAs(file);
        });
    },
  },
};
</script>
