var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('wolk-private-data-alert')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Exports "),_c('v-spacer')],1),_c('v-card-text',[_c('p',[_vm._v("Selecteer een export, de van en tot datum en klik op downloaden")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Gebruikers logins "),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"userTokensFromDateDialog",attrs:{"return-value":_vm.userTokens.fromDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.userTokens, "fromDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.userTokens, "fromDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":true,"label":"Van","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.humanizedUserTokensFromDate),callback:function ($$v) {_vm.humanizedUserTokensFromDate=$$v},expression:"humanizedUserTokensFromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.userTokens.fromDateModal),callback:function ($$v) {_vm.$set(_vm.userTokens, "fromDateModal", $$v)},expression:"userTokens.fromDateModal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.userTokens.fromDate),callback:function ($$v) {_vm.$set(_vm.userTokens, "fromDate", $$v)},expression:"userTokens.fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.userTokens.fromDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.userTokensFromDateDialog.save(_vm.userTokens.fromDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"userTokensToDateDialog",attrs:{"return-value":_vm.userTokens.toDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.userTokens, "toDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.userTokens, "toDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":true,"label":"Tot","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.humanizedUserTokensToDate),callback:function ($$v) {_vm.humanizedUserTokensToDate=$$v},expression:"humanizedUserTokensToDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.userTokens.toDateModal),callback:function ($$v) {_vm.$set(_vm.userTokens, "toDateModal", $$v)},expression:"userTokens.toDateModal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.userTokens.toDate),callback:function ($$v) {_vm.$set(_vm.userTokens, "toDate", $$v)},expression:"userTokens.toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.userTokens.toDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.userTokensToDateDialog.save(_vm.userTokens.toDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.downloadUserTokenData}},[_vm._v(" Downloaden ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Organisatie data "),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"organizationDataFromDateDialog",attrs:{"return-value":_vm.organizationData.fromDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.organizationData, "fromDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.organizationData, "fromDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":true,"label":"Van","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.humanizedOrganizationDataFromDate),callback:function ($$v) {_vm.humanizedOrganizationDataFromDate=$$v},expression:"humanizedOrganizationDataFromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.organizationData.fromDateModal),callback:function ($$v) {_vm.$set(_vm.organizationData, "fromDateModal", $$v)},expression:"organizationData.fromDateModal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.organizationData.fromDate),callback:function ($$v) {_vm.$set(_vm.organizationData, "fromDate", $$v)},expression:"organizationData.fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.organizationData.fromDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.organizationDataFromDateDialog.save(_vm.organizationData.fromDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"organizationDataToDateDialog",attrs:{"return-value":_vm.organizationData.toDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.organizationData, "toDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.organizationData, "toDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":true,"label":"Tot","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.humanizedOrganizationDataToDate),callback:function ($$v) {_vm.humanizedOrganizationDataToDate=$$v},expression:"humanizedOrganizationDataToDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.organizationData.toDateModal),callback:function ($$v) {_vm.$set(_vm.organizationData, "toDateModal", $$v)},expression:"organizationData.toDateModal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.organizationData.toDate),callback:function ($$v) {_vm.$set(_vm.organizationData, "toDate", $$v)},expression:"organizationData.toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.organizationData.toDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.organizationDataToDateDialog.save(_vm.organizationData.toDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.organizations,"item-text":"title","item-value":"_id","prepend-icon":"mdi-domain","label":"Organisaties","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 5)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.title))])]):_vm._e(),(index === 5)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (en "+_vm._s(_vm.organizationData.selectedIds.length - 5)+" anderen) ")]):_vm._e()]}}]),model:{value:(_vm.organizationData.selectedIds),callback:function ($$v) {_vm.$set(_vm.organizationData, "selectedIds", $$v)},expression:"organizationData.selectedIds"}}),_c('v-btn',{on:{"click":_vm.toggleAllOrganizations}},[_vm._v(" "+_vm._s(_vm.organizationData.selectedIds.length === 0 ? 'Selecteer' : 'Deselecteer')+" alle organisaties ")])],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.canRequestOrganizationData)?_c('v-alert',{attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" Kies een kleinere selectie ")]):_vm._e(),_c('v-btn',{attrs:{"disabled":!_vm.canRequestOrganizationData,"color":"primary"},on:{"click":_vm.downloadOrganizationAnalytics}},[_vm._v(" Downloaden ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }